import { render, staticRenderFns } from "./buyerBillOrder.vue?vue&type=template&id=610a12ac&"
import script from "./buyerBillOrder.vue?vue&type=script&lang=js&"
export * from "./buyerBillOrder.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports